import {Injectable} from '@angular/core';

@Injectable()
export class JwtService {

  private token = '';

  constructor() {
    this.getToken();
  }

  public parseToken(jwt?) {
    const token = jwt || this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public setToken(token: string) {
    localStorage.setItem('auth-token', token);
    this.token = token;
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('auth-token');
    }
    return this.token;
  }

  public deleteToken() {
    localStorage.removeItem('auth-token');
    this.token = '';
  }
}
