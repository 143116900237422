import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CountdownService {

  private timerId: number;
  private readonly time: BehaviorSubject<number>;

  constructor(
  ) {
    this.time = new BehaviorSubject<number>(15 * 60);
  }

  public start() {
    this.timerId = window.setInterval(() => {
      this.time.next(this.time.getValue() - 1);
    }, 1000);
  }

  public stop() {
    clearInterval(this.timerId);
    this.timerId = null;
    this.time.next(15 * 60);
  }

  public getTime() {
    return this.time;
  }
}
