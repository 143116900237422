import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {NotFoundComponent} from './components/not-found/not-found.component';


const external: Routes = [
  {path: '', loadChildren: './components/external/external.module#ExternalModule'},
  {path: 'page-not-found', component: NotFoundComponent},
  {path: '**', redirectTo: '/page-not-found'}
];

const fsin: Routes = [
  {path: '', loadChildren: './components/fsin/fsin.module#FsinModule'},
  {path: 'page-not-found', component: NotFoundComponent},
  {path: '**', redirectTo: '/page-not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(fsin, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
